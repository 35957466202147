export const termsandconditionsPage = {
    title: `<span>Terms & Conditions</span>`,
    description: `By accessing or using our Website or Platform, you agree to be bound by these Terms of Use and our Privacy Policy.`,
    googleReviewText: `Care Casa’s SDA home services have completely transformed my life. I have gained the independence and comfort I desire. I highly recommend them!`,
    contentSections: [
        {
            title: `1. Introduction`,
            titleEC: `!mt-0`,
            description: ` `,
            list: [
                `Care Casa (referred to as “Care Casa”, “we”, or “us”) operates a website and online platform accessible at carecasa.com.au (collectively referred to as the "Platform"). Our Platform connects individuals seeking Specialist Disability Accommodation (SDA) housing under the NDIS scheme and related services ("Clients") with available properties and services provided by Care Casa.`,
                `By accessing or using our Website or Platform, you agree to be bound by these Terms of Use and our Privacy Policy (collectively referred to as the “Agreement”). If you do not agree with any part of this Agreement, you must immediately stop using our Website and Platform.`
            ]
        },
        {
            title: `2. Scope of Care Casa Platform`,
            description: ` `,
            list: [
                `Care Casa solely provides the Platform to facilitate access to our SDA housing properties. We are not responsible or liable for the outcomes of any interactions, agreements, or arrangements between Clients and any third-party service providers.`,
                `The information on our Platform is provided for general informational purposes only. We do not verify the accuracy, completeness, or suitability of any property listings, descriptions, or other content posted.`,
            ]
        },
        {
            title: `3. Registration and Account Management`,
            description: ` `,
            list: [
                `You must be over 18 years of age to register and use our Platform. When creating an account, you agree to provide accurate and up-to-date information and to keep your login credentials secure.`,
                `We reserve the right to refuse or cancel any account registration at our discretion, including if we believe that the information provided is false, misleading, or violates this Agreement.`,
            ]
        },
        {
            title: `4. Services Provided`,
            description: ` `,
            list: [
                `Access to the Platform is free for Clients. By creating an account, you can receive a personalised experience, including notifications and updates on SDA housing availability.`,
                `All property listings and related services are provided in accordance with NDIS policies. We encourage you to conduct your own due diligence before engaging with any listed property.`,
            ]
        },
        {
            title: `5. Client Obligations`,
            description: ` `,
            list: [
                `When using the Platform, you agree to: 
                <br><br>
                <ul class="list-disc">
                    <li>Comply with this Agreement.</li>
                    <li>Use the Platform solely for seeking SDA housing and related services.</li>
                    <li>Maintain the confidentiality of your login credentials and notify us immediately of any unauthorised access.</li>
                    <li>Ensure that any content you provide or post on the Platform does not infringe on third-party rights, violate laws, or contain harmful or offensive material.</li>
                </ul>`,
                `You are solely responsible for assessing the suitability of any SDA housing or services for your needs. Care Casa does not guarantee the quality, suitability, or availability of properties listed on the Platform.`                
            ]
        },
        {
            title: `6. Intellectual Property`,
            description: ` `,
            list: [
                `Care Casa owns all intellectual property rights in the Platform, including content, logos, and technology. You may not copy, modify, distribute, or exploit any content from our Platform without our explicit consent.`,
                `You must not use automated processes to access or scrape data from the Platform, nor attempt to reverse-engineer any part of our services.`,
            ]
        },
        {
            title: `7. Third-Party Links and Services`,
            description: ` `,
            list: [
                `Our Platform may contain links to third-party websites or services. These are provided for convenience only, and we do not endorse or take responsibility for any content, products, or services offered by third parties.`,
                `You acknowledge that the use of third-party services, including Google Maps for location details, is subject to their respective terms and conditions.`,
            ]
        },
        {
            title: `8. Privacy`,
            description: ` `,
            list: [
                `Your use of the Platform is governed by our Privacy Policy. By using the Platform, you consent to the collection, use, and disclosure of your personal information as outlined in the Privacy Policy.`,
                `You are responsible for ensuring that any personal information you provide about others is shared with their consent.`,
            ]
        },
        {
            title: `9. Limitation of Liability`,
            description: ` `,
            list: [
                `To the extent permitted by law, Care Casa disclaims all liability for any loss or damage (direct, indirect, or consequential) arising out of your use of the Platform or reliance on information provided.`,
                `The Platform is provided "as is", without warranties of any kind, either express or implied. We do not guarantee uninterrupted access to the Platform or the accuracy of any content.`,
            ]
        },
        {
            title: `10. Modifications to the Agreement`,
            description: ` `,
            list: [
                `We may update these Terms of Use from time to time. Any changes will be posted on our Platform, and your continued use of the Platform constitutes acceptance of the updated terms.`,
                `If you do not agree with any changes, you must stop using the Platform immediately.`,
            ]
        },
        {
            title: `11. Termination`,
            description: ` `,
            list: [
                `You may terminate your account at any time by contacting us at accounts@carecasa.com.au. We may also terminate or suspend your account for any reason, including breach of this Agreement.`,
                `Termination of your account does not affect your obligations under any agreements you have entered into with third-party providers.`,
            ]
        },
        {
            title: `12. Governing Law`,
            description: ` `,
            list: [
                `This Agreement is governed by the laws of South Australia. You agree to submit to the exclusive jurisdiction of the courts of South Australia in relation to any disputes arising from your use of the Platform.`,
            ]
        },
        {
            title: `13. General Provisions`,
            description: ` `,
            list: [
                `If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.`,
                `Our failure to enforce any right or provision of this Agreement does not constitute a waiver of that right.`,
                `This Agreement constitutes the entire agreement between you and Care Casa regarding the use of our Platform.`
            ]
        },
        {
            title: `Contact Information`,
            description: `For any questions or concerns regarding these Terms of Use, please contact us at:
            <br>
            Email: <a href="mailto:accounts@carecasa.com.au">accounts@carecasa.com.au</a>
            <br>
            Phone: <a href="tel:+61874271805">(08) 7427 1805</a>`,
        },
    ]
}